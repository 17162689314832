<template>
  <div>
    <div class="sai-tagged-input" :class="{ onFocus: show }">
      <div class="sai-tagged-input__contents">
        <template v-for="tag in selectedTags">
          <a
            class="sai-tagged-input__contents__tag"
            :class="tag.type === 'node' && 'category'"
            v-if="
              ($store.state.user.isSP && tag.type !== 'node') ||
                !$store.state.user.isSP
            "
            :key="tag.id"
          >
            {{ tag.displayText || tag.text }}
            <a
              class="sai-tagged-input__contents__tag__close"
              @click.prevent="removeTag(tag)"
              ><font-awesome-icon icon="times" />
            </a>
          </a>
        </template>
        <input
          class="sai-tagged-input__contents__input"
          type="text"
          size="1"
          :value="searchText"
          :placeholder="placeholderMessage"
          @input="customUpdateSearchText($event.target.value)"
          @focus="onFocusWithScroll"
          @keyup="keyupHandler"
          @keydown="keydownHandler"
          @keypress="onKeypress"
          @compositionstart="onCompositionStart"
          @compositionend="onCompositionEnd"
          ref="searchInput"
        />
      </div>
      <div class="sai-tagged-input__functions" v-show="show">
        <div class="sai-tagged-input__functions__border"></div>
        <div class="sai-tagged-input__functions__close">
          <a
            class="sai-tagged-input__functions__close__icon"
            @click.prevent="closeList"
          >
            <img src="~@/resource/sai-icon_slim_x.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TaggedInputMixin from '@/mixins/TaggedInputMixin';

@Component({})
export default class TaggedInput extends mixins(TaggedInputMixin) {}
</script>

<style lang="scss" scoped>
@import '../style/component/taggedInput';
</style>
