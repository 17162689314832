import { AsyncOutOfOrderError, AsyncUpdate } from '@/common/util';
import tagSearch from '@/common/tagSearch';

class OrderedSet {
  set = new Set();
  updateId = 1;
  list = [];
  has(item) {
    return this.set.has(item);
  }
  delete(item) {
    if (this.set.has(item)) {
      this.set.delete(item);
      this.list = this.list.filter(_item => _item !== item);
    }
    this.updateId += 1;
  }
  add(item) {
    if (!this.set.has(item)) {
      this.set.add(item);
      this.list.push(item);
    }
    this.updateId += 1;
  }
  values() {
    return this.list;
  }
  [Symbol.iterator]() {
    return this.set[Symbol.iterator]();
  }
}

export default {
  namespaced: true,
  state: {
    show: false,
    searchText: '',
    searchQuery: '',
    result: [],
    topTags: {},
    initialTags: [],
    selectedTags: new OrderedSet(),
    candidateTags: [], // dependency: filteredItems
    filteredItems: [], // dependency: searchText,selectedTags
    candidateTagsUpdater: new AsyncUpdate(), // 非同期更新の順序を保つ
    filteredItemsUpdater: new AsyncUpdate(), // 非同期更新の順序を保つ
  },
  getters: {
    getSearchText: state => {
      return state.searchText;
    },
    selectedKeywordTags: state => {
      return state.selectedTags.values().filter(t => {
        return t.type === 'keyword';
      });
    },
    selectedCategoryTags: state => {
      return state.selectedTags.values().filter(t => {
        return t.type === 'node';
      });
    },
  },
  mutations: {
    setShow(state, value) {
      state.show = value;
    },
    setSearchText(state, searchText) {
      state.searchText = searchText;
    },
    setTopTags(state, topTags) {
      state.topTags = topTags;
    },
    setInitialTags(state, initialTags) {
      state.initialTags = initialTags;
    },
    removeSelectedTag(state, tag) {
      state.selectedTags.delete(tag);
    },
    addSelectedTag(state, tag) {
      state.selectedTags.add(tag);
    },
    initSelectedTags(state) {
      state.selectedTags = new OrderedSet();
    },
    setFilteredItems(state, filteredItems) {
      state.filteredItems = filteredItems;
    },
    setCandidateTags(state, candidateTags) {
      state.candidateTags = candidateTags;
    },
  },
  actions: {
    async init({ commit, dispatch }) {
      const topTags = await tagSearch.getTopTagsByYomi();
      commit('setTopTags', topTags);
      const initialTags = await tagSearch.getInitialTags();
      commit('setInitialTags', initialTags);
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems');
    },
    async setSearchText(
      { state, commit, dispatch },
      searchText,
      mode = 'both'
    ) {
      // if (state.searchText != searchText){
      // to be cleaned up
      commit('setSearchText', searchText);
      if (mode === 'tag' || mode === 'both') {
        dispatch('updateCandidateTags');
      }
      if (mode === 'item' || mode === 'both') {
        await dispatch('updateFilteredItems', true);
      }
      // }
      // return
    },
    async removeSelectedTag({ commit, dispatch }, tag) {
      commit('removeSelectedTag', tag);
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems', true);
    },
    async removeTagByName({ commit, dispatch, state }, text) {
      state.selectedTags
        .values()
        .filter(t => t.text === text)
        .forEach(tag => {
          commit('removeSelectedTag', tag);
        });
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems', true);
    },
    async addSelectedTag({ commit, dispatch, state }, tag) {
      // const addFlag = state.selectedTags.values().every(st=>{
      //   return st.text !== tag.text
      // })
      // if (addFlag){
      commit('setSearchText', '');
      commit('addSelectedTag', tag);
      // commit('setShow', true);
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems');
      // }
    },
    // NOTE:スマホでキーワード入力がされている状態でカテゴリを選択した時にキーワードを消えないようにメソッドを分けている
    async addSelectedTagForSP({ commit, dispatch, state }, tag) {
      commit('addSelectedTag', tag);
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems');
    },
    async resetInput({ commit, dispatch }) {
      commit('initSelectedTags');
      commit('setSearchText', '');
      await dispatch('updateTagsItems');
    },
    async updateTagsItems({ dispatch }) {
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems');
    },
    async tagifySeachText({ commit, state, dispatch }) {
      const tagsToBeAdded = await tagSearch.tagify(state.searchText);
      if (tagsToBeAdded.length > 0) {
        state.searchText = '';
        tagsToBeAdded.forEach(async tag => {
          await dispatch('addSelectedTag', tag);
        });
      }
      return tagsToBeAdded;
    },
    async tagifyText({ dispatch }, text) {
      const tagsToBeAdded = await tagSearch.tagify(text);
      tagsToBeAdded.forEach(async tag => {
        await dispatch('addSelectedTag', tag);
      });
      return tagsToBeAdded;
    },
    async tagifyTextExact({ dispatch }, text) {
      const tagsToBeAdded = await tagSearch.tagify(text);
      const exactTags = tagsToBeAdded.filter(t => {
        return t.index === text;
      });
      exactTags.forEach(async tag => {
        await dispatch('addSelectedTag', tag);
      });
      return exactTags;
    },
    async removeLastTag({ commit, state, dispatch }) {
      if (!state.searchText) {
        const lastTag = [...state.selectedTags].pop();
        commit('removeSelectedTag', lastTag);
        await dispatch('updateCandidateTags');
        await dispatch('updateFilteredItems');
      }
    },
    async removeLastCategory({ commit, state, dispatch }) {
      const lastCategory = state.selectedTags
        .values()
        .findLast(t => t.type === 'node');
      commit('removeSelectedTag', lastCategory);
      await dispatch('updateCandidateTags');
      await dispatch('updateFilteredItems');
    },
    async updateCandidateTags({ commit, state }) {
      try {
        // commit('setShow', true);
        const selectedTags = state.selectedTags
          .values()
          .filter(t => t.type === 'keyword');
        // .map(t => t.text);

        const selectedCategory = state.selectedTags
          .values()
          .filter(t => t.type === 'node');
        // .map(t => t.text);

        const tagsQuery = selectedTags.map(t => t.text).join(' ');
        const categoryQuery = selectedCategory.join(' ');

        const textQuery = state.searchText;
        if (tagsQuery.length > 0 && textQuery.length > 0) {
          state.searchQuery = `${tagsQuery} ${textQuery}`;
        } else {
          state.searchQuery = tagsQuery + textQuery;
        }

        const query = textQuery;
        // const query = selectedCategory.length>0 ? textQuery||categoryQuery : textQuery
        const searchCondition = { category: selectedCategory };
        const candidateTags = await state.candidateTagsUpdater.request(() => {
          return tagsQuery.length > 0 || categoryQuery.length > 0 || textQuery
            ? tagSearch.getCandidateTags(selectedTags, query, searchCondition)
            : tagSearch.getInitialTags();
        });
        commit('setCandidateTags', candidateTags);
      } catch (e) {
        if (e instanceof AsyncOutOfOrderError) {
          console.error(e);
        } else {
          throw e;
        }
      }
    },
    async updateFilteredItems({ commit, state, dispatch }, withSearch = false) {
      try {
        const selectedTagList = state.selectedTags.values();
        const filteredItems = await state.filteredItemsUpdater.request(() =>
          tagSearch.getCandidateScript(
            selectedTagList,
            withSearch ? state.searchText : null
          )
        );
        commit('setFilteredItems', filteredItems);
        // state.searchText = '';
      } catch (e) {
        if (e instanceof AsyncOutOfOrderError) {
          console.error(e);
        } else {
          throw e;
        }
      }
    },
  },
};
