import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { eventBus } from '@/eventBus';

const bound = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

@Component({
  // components: columnComponents,
  props: {
    range: Object,
  },
})
export default class NavigationMixin extends Vue {
  index = 0;
  componentWidth = 0;
  columnWidth = 300;
  localMap = new Map();
  locals = [];
  // localParamsMap = new Map();
  canMovePositionBy(relative) {
    const newLocalIndex = this.localIndex + relative;
    return newLocalIndex >= 0 && newLocalIndex < this.routes.length;
  }
  async movePositionBy(relative) {
    if (relative === -1) {
      // 前に戻ったら検索フィルターから対象のカテゴリ削除
      await this.$store.dispatch('tagSearch/removeLastCategory');
    } else if (relative === 1) {
      // 次に進んだら検索フィルターに対象のカテゴリ追加
      const routes = this.$store.getters['navigation/getRoutes'];
      const index = this.$store.getters['navigation/getIndex'];
      await this.$store.dispatch(
        'tagSearch/addSelectedTag',
        routes[index + relative]
      );
    }

    const newLocalIndex = this.localIndex + relative;
    await this.$store.dispatch(
      'navigation/movePositionTo',
      this.baseIndex + newLocalIndex
    );
    const routeItem = this.$store.getters['navigation/getRoute'];
    if (
      routeItem.viewType === 'talkScript' &&
      this.$store.state.ticket.resetFlag
    ) {
      eventBus.$emit('resetTicket');
      return;
    }
    if (routeItem.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateTicket(routeItem);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  get baseIndex() {
    if (this.range) {
      return this.range.start;
    } else {
      return 0;
    }
  }
  get routes() {
    if (this.range) {
      return this.$store.state.navigation.routes.slice(
        this.range.start,
        this.range.end
      );
    } else {
      return this.$store.state.navigation.routes;
    }
  }
  get localIndex() {
    return bound(
      this.$store.state.navigation.index - this.baseIndex,
      0,
      this.routes.length - 1
    );
  }
  get navigationLeft() {
    return Math.min(
      this.componentWidth - (this.localIndex + 1) * this.componentWidth,
      0
    );
  }
  @Watch('routes', { immediate: true })
  onUpdateRoute(routes) {
    const oldLocalMap = this.localMap;
    const localMap = new Map();
    const locals = routes.map(route => {
      const local = oldLocalMap.get(route) || {
        scrollPosition: 0,
        selectedPosition: null,
      };
      localMap.set(route, local);
      return local;
    });
    this.localMap = localMap;
    this.locals = locals;
  }
  getLocalParams(route) {
    if (!route) {
      return null;
    }
    if (!this.localParamsMap.has(route)) {
      this.localParamsMap.set(route, {
        scrollPosition: 0,
        selectedPosition: null,
      });
    }
    return this.localParamsMap.get(route);
  }
  updateComponentWidth() {
    if (this.$refs.navigationContent) {
      this.componentWidth = this.$refs.navigationContent.clientWidth;
    }
  }
  // index dependent method
  getColumnLeft(index) {
    return index * this.componentWidth;
  }
  getColumnWidth(index) {
    return this.componentWidth;
  }
  async initTouch() {
    // handle swipe
    let startTouch = null;
    let lastTouch = null;
    const allowableSlope = Math.tan(Math.PI / 6);
    // この角度に収まっていなければ、スワイプを解除する
    const cancelTouch = () => {
      this.$refs.navigationContent.style.transition = null;
      setTimeout(() => {
        this.$refs.navigationContent.style.left = this.getNavigationLeft(true);
      }, 0);
    };
    this.$refs.navigationContent.addEventListener('touchstart', e => {
      const currentTouch = e.targetTouches[0];
      startTouch = currentTouch;
      lastTouch = currentTouch;
    });
    this.$refs.navigationContent.addEventListener('touchmove', e => {
      if (!startTouch) {
        return;
      }
      this.$refs.navigationContent.style.transition = 'none';
      lastTouch = e.targetTouches[0];
      const diffX = lastTouch.clientX - startTouch.clientX;
      const diffY = lastTouch.clientY - startTouch.clientY;
      if (
        Math.abs(diffX) * allowableSlope > Math.abs(diffY) ||
        Math.abs(diffY) < 20
      ) {
        if (
          Math.abs(diffX) > 20 &&
          ((diffX < 0 && this.canMovePositionBy(1)) ||
            (diffX > 0 && this.canMovePositionBy(-1)))
        ) {
          e.preventDefault();
          this.$refs.navigationContent.style.left =
            this.navigationLeft +
            Math.sign(diffX) * Math.max(0, Math.abs(diffX) - 40) +
            'px';
        }
      } else {
        startTouch = false;
        cancelTouch();
      }
    });
    this.$refs.navigationContent.addEventListener('touchend', e => {
      if (!startTouch) {
        return;
      }
      const diffX = lastTouch.clientX - startTouch.clientX;
      let direction = 0;
      if (diffX < -this.columnWidth / 3) {
        direction = 1;
      } else if (diffX > this.columnWidth / 3) {
        direction = -1;
      }
      if (direction !== 0 && this.canMovePositionBy(direction)) {
        this.movePositionBy(direction);
        cancelTouch();
      }
    });
  }
  getNavigationLeft(withoutUnit) {
    const value = Math.min(
      -this.getCumulativeWidth(this.$store.state.navigation.index) +
        this.$refs.navigationContent.clientWidth,
      0
    );
    return value;
    // return withoutUnit ? value : value + this.tag.columnWidthUnit;
  }
  getCumulativeWidth(index) {
    if (index < 0) {
      return 0;
    }
    if (!this.locals[index].cumulativeWidth) {
      this.locals[index].cumulativeWidth =
        this.getCumulativeWidth(index - 1) + this.getColumnWidth(index);
    }
    return this.locals[index].cumulativeWidth;
  }
  updateTicket(item) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    if (item.talkScriptId) {
      const historyAction = { type: '', value: 'category' };
      this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    }
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(item, startTime, query, tagActiveSet);
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return { ...commonTicket, ...ticketParams };
  }

  // lifecycle methods
  mounted() {
    this.updateComponentWidth();
    window.addEventListener('resize', this.updateComponentWidth);
    this.initTouch();
  }
  unmounted() {
    window.removeEventListener('resize', this.updateComponentWidth);
  }
}
