import Vue from 'vue';
import Component from 'vue-class-component';
import dataResource from '@/common/dataResource';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { scrollIntoViewX } from '@/common/scrollUtil';
import { eventBus } from '@/eventBus';

const bound = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

@Component({
  props: {
    range: Object,
  },
})
export default class BreadcrumbsMixin extends Vue {
  scrollTarget = '.sai-breadcrumbs__content__items__item.selected';
  topText = this.$store.state.constObj.BREADCRUMB_TOP_TEXT;

  get baseIndex() {
    if (this.range) {
      return this.range.start;
    } else {
      return 0;
    }
  }
  get routes() {
    if (this.range) {
      return this.$store.state.navigation.routes.slice(
        this.range.start,
        this.range.end + 1
      );
    } else {
      return this.$store.state.navigation.routes;
    }
  }
  get localIndex() {
    return bound(
      this.$store.state.navigation.index - this.baseIndex,
      0,
      this.routes.length - 1
    );
  }
  async move(newLocalIndex) {
    const routes = this.$store.getters['navigation/getRoutes'];
    const selectedTags = routes.filter(v => v.viewType === 'talkScript');
    const currentIndex = this.$store.getters['navigation/getIndex'];

    // 現在より上階層のカテゴリを選択した場合、選択したカテゴリより下のパンくずリストを全て削除
    if (currentIndex !== newLocalIndex) {
      for (let i = selectedTags.length; i > newLocalIndex + 1; i--) {
        await this.$store.dispatch('tagSearch/removeLastCategory');
      }
    }

    await this.movePositionTo(newLocalIndex);
    const routeItem = this.$store.getters['navigation/getRoute'];
    if (
      routeItem.viewType === 'talkScript' &&
      this.$store.state.ticket.resetFlag
    ) {
      eventBus.$emit('resetTicket');
      return;
    }
    if (routeItem.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = this.updateTicket(routeItem);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async movePositionTo(newLocalIndex) {
    const newIndex = this.baseIndex + newLocalIndex;
    await this.$store.dispatch('navigation/movePositionTo', newIndex);
  }
  generateTitle(text) {
    return text.length > 10 ? `${text.slice(0, 10)}...` : text;
  }
  @AsyncComputed()
  async list() {
    const list = await Promise.all(
      this.routes &&
        this.routes
          .filter((_, idx) => idx <= this.localIndex)
          .map(route => dataResource.getItem(route))
    );
    return list.filter(item => item);
  }
  async searchClear() {
    await eventBus.$emit('reset');
    await this.movePositionTo(0);
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);

    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      status: 'open',
    };

    return newTicket;
  }
  updateTicket(item) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    if (item.talkScriptId) {
      const historyAction = { type: '', value: 'category' };
      this.$store.commit('ticket/addHistoryActionFaqChannel', historyAction);
    }
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(item, startTime, query, tagActiveSet);
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return { ...commonTicket, ...ticketParams };
  }
  isBigCategory(item) {
    return item.parent && item.parent.talkScriptId === '#';
  }
  updated() {
    const selectedElement = this.$el.querySelector(this.scrollTarget);
    if (selectedElement) {
      scrollIntoViewX(selectedElement, 'center');
    }
  }
}
